import React, {
    useContext
} from 'react'


const UserContext = React.createContext({})
const SetUserContext = React.createContext(()=>{})

export function useUser() {
    return useContext(UserContext)
}

export function useSetUser() {
    return useContext(SetUserContext)
}

export function UserProvider ({children}) {
    const [user, setUser] = React.useState({})

    return (
        <UserContext.Provider value={user}>
            <SetUserContext.Provider value={setUser}>
                {children}
            </SetUserContext.Provider>
        </UserContext.Provider>
    )
}