import React, { useState, useEffect } from "react";
import axios from "axios";

// components

import CardTable from "components/Cards/CardTable.js";
import { API_KEY } from "config";
import Loading from "views/Loading";
import { query, collection, onSnapshot, db } from "firebase";
// import { useUser } from "contexts/user-context";
// import { OG_API_KEY } from "config";

export default function Offers() {
  const [offers, setOffers] = useState([]);
  const [completed_offers, setCompleted_offers] = useState([]);
  const [loading, setLoading] = useState(true);
  // const userData = useUser()

  const getData = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");
    return { ...res.data, userAgent: window.navigator.userAgent };
  };

  useEffect(async () => {
    const user_data = await getData();
    console.log(user_data);
    const user_id1 = "314997";
    // const user_id2 = '190820'
    // const ctype = '12'
    // const pubkey = '1211a8859f55aa7e42b7b7714c31fcde'
    const url1 = `https://d2ze9scn6abmic.cloudfront.net/public/offers/feed.php?user_id=${user_id1}&api_key=${API_KEY}&s1=&s2=&ip=${
      user_data.IPv4 ?? ""
    }&user_agent=${user_data.userAgent}&callback=?`;
    // const url2 = `https://protrkr.com/common/offer_feed_json.php?user_id=${user_id2}&pubkey=${pubkey}&tracking_id=${user_data.IPv4 ?? ''}&ip=${user_data.IPv4}`
    // const url3 = `https://nozty-cpa-apis.herokuapp.com/og-offers?user_ip=${user_data.IPv4 ?? ''}&user_agent=${user_data.userAgent ?? ''}&ctype=${ctype}`
    axios
      .get(url1)
      .then((response) => {
        const data = JSON.parse(
          response?.data[0] == "?"
            ? response?.data?.slice(2, response?.data.length - 1)
            : response?.data
        );
        console.log(data);
        setOffers(data);

        // console.log(response.data.offers)
        // setOffers(
        //   response?.data?.offers?.map((offer) => ({
        //     ...offer,
        //     name: offer.title,
        //     anchor: offer.description,
        //     id: offer.offer_id,
        //     url: offer.offerlink,
        //     network_icon: offer.offerphoto
        //   }))
        // )

        // const data = response?.data
        // console.log(data)
        // setOffers(data)

        setLoading(false);
        // console.log(JSON.parse(data.slice(2,data.length-2)))
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, []);

  useEffect(async () => {
    const user_data = await getData();
    const q = query(collection(db, "Custom", user_data.IPv4, "Leads"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      if (querySnapshot.docs) {
        setCompleted_offers(
          querySnapshot?.docs?.map((doc) => ({
            ...doc.data(),
          }))
        );

        // const cities = [];
        // querySnapshot?.forEach((doc) => {
        //     cities.push(doc.data())
        // })
        // console.log(cities)
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  // useEffect(async () => {
  //   const user_id = '262103'
  //   const interval = setInterval(() => {
  //     axios.get(`https://d3t3bxixsojwre.cloudfront.net/public/external/check2.php?user_id=${user_id}&api_key=${API_KEY}&s1=&s2=&ip=${"193.27.13.30"}&user_agent=${"Mozilla/5.0 (Linux; Android 6.0; Nexus 5 Build/MRA58N) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/202.0.0.0 Mobile Safari/537.36"}&testing=1&callback=?`)
  //     .then((leads) => {
  //       var completed_leads = leads.length
  //       console.log(leads)
  // 			// if (completed_leads) {
  // 			// 	var offer_ids = []
  // 			// 	var earnings_in_cents = 0
  // 			// 	leads.forEach((key, lead) => {
  // 			// 		offer_ids.push(parseInt(lead.offer_id))
  // 			// 		earnings_in_cents += parseFloat(lead.points)
  // 			// 		console.log("Single lead on offer id " + lead.offer_id + " for  $" + (parseFloat(lead.points) / 100).toFixed(2))
  // 			// 	});
  // 			// 	console.log("SUMMARY: User has completed " + completed_leads + " leads, for $" + (earnings_in_cents / 100) + " earnings, on offer ids: " + offer_ids.join(","))
  // 			// }
  // 			// else {
  // 			// 	console.log("No leads were found")
  // 			// }
  //     }).catch((error) => {
  //       console.log(error.message)
  //     })
  //   }, 5000);
  //   return () => clearInterval(interval);
  // }, []);

  const open = () => {
    if (
      offers?.filter(
        (offer) =>
          completed_offers?.filter((co) => co.offer_id === offer.id)?.length > 0
      ).length >= 2
    ) {
      window
        .open(
          "https://store.steampowered.com/app/1966720/Lethal_Company/?curator_clanid=4777282",
          "_blank"
        )
        .focus();
    }
  };
  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className="px-4 md:px-10 flex flex-wrap">
          <div
            style={{
              width: "100%",
              margin: "40px auto 10px",
              alignSelf: "center",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src={require("../../assets/img/logo.jpeg").default}
              alt="Caera"
              style={{ width: 80, height: 80, borderRadius: "10px" }}
            />
            <h1
              style={{
                fontWeight: "bold",
                padding: "20px 0 10px",
                textTransform: "uppercase",
              }}
            >
              Lethal Company
            </h1>
            <h2
              style={{
                textAlign: "center",
                padding: "0 0 10px",
                fontSize: "14px",
              }}
            >
              Please Verify to start the DOWNLOAD
            </h2>
            <p
              style={{
                textAlign: "center",
                fontSize: "12px",
                marginTop: "10px",
                color: "gray",
                fontWeight: "bold",
              }}
            >
              You can verify by completing two from the offers below
            </p>
          </div>
          <div className="w-full mb-0 px-0 mt-6">
            <CardTable
              title="Available Offers"
              data={offers}
              completed_offers={completed_offers}
            />
          </div>
          <div
            style={{
              width: "100%",
              margin: "0px auto 20px",
              alignSelf: "center",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <p
              style={{
                textAlign: "center",
                padding: "0 0 10px",
                fontSize: "15px",
                fontWeight: "bold",
              }}
            >
              Completed offers:{" "}
              {
                offers?.filter(
                  (offer) =>
                    completed_offers?.filter((co) => co.offer_id === offer.id)
                      ?.length > 0
                ).length
              }
              /2
            </p>
            <button
              onClick={open}
              style={{
                textAlign: "center",
                padding: "10px 40px",
                fontSize: "15px",
                fontWeight: "bold",
                color: "#fff",
                backgroundColor:
                  offers?.filter(
                    (offer) =>
                      completed_offers?.filter((co) => co.offer_id === offer.id)
                        ?.length > 0
                  ).length >= 3
                    ? "green"
                    : "gray",
                borderRadius: "8px",
                textDecoration: "none",
              }}
            >
              Continue
            </button>
          </div>
          <div className="w-full mb-12 px-0">
            <CardTable
              color="dark"
              title="Offers History"
              data={completed_offers}
              completed_offers={completed_offers}
              completed
            />
          </div>
        </div>
      )}
    </>
  );
}
