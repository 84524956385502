import React from 'react'

function Loading() {
  return (
    <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100vh',
        position: 'absolute',
        top: '0',
        left: '0',
        zIndex: 100,
        backgroundColor: '#ddd'
    }}>
        <p style={{
            fontSize: '20px',
            color: '#666',
            fontWeight: 'bold'
        }}>
            Loading ...
        </p>
    </div>
  )
}

export default Loading