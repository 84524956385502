import { initializeApp } from "firebase/app";
import {
  getAuth,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  updateProfile,
  signOut,
  createUserWithEmailAndPassword,
} from "firebase/auth";
import {
  getFirestore,
  collection,
  addDoc,
  getDocs,
  where,
  query,
  updateDoc,
  doc,
  serverTimestamp,
  increment,
  onSnapshot,
  setDoc,
} from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDeUnDKxWVhmHrS07xInhgoOkNAyAtYeDU",
  authDomain: "lethal-company-locker.firebaseapp.com",
  databaseURL: "https://lethal-company-locker-default-rtdb.firebaseio.com",
  projectId: "lethal-company-locker",
  storageBucket: "lethal-company-locker.appspot.com",
  messagingSenderId: "824753360439",
  appId: "1:824753360439:web:54cc171dbe1361bdb739b2",
  measurementId: "G-19CTYD7V8S",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);

export {
  db,
  auth,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  updateProfile,
  signOut,
  createUserWithEmailAndPassword,
  collection,
  addDoc,
  getDocs,
  where,
  query,
  updateDoc,
  doc,
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
  serverTimestamp,
  increment,
  onSnapshot,
  setDoc,
};
